











































import { Vue, Component } from 'vue-property-decorator';

@Component({
    name: 'HomePage',
    components: {
    }
})
export default class HomePage extends Vue {
}
